@import url('https://fonts.googleapis.com/css2?family=Poppins');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-size: 14px;
  font-family: 'Poppins', sans-serif !important;
}

svg {
  vertical-align: baseline !important;
}

.logo {
  padding: 25px;
}

.bg-white {
  background-color: white;
}

.container {
  padding: 24px 20px;
  /* background: #F7F8FB; */
  max-width: 100% !important;
}

.header {
  padding: 25px 20px;
  display: flex;
  justify-content: flex-start;
}

/* .header {
  padding: 25px 20px;
  display: flex;
  justify-content: flex-end;
} */

span.username {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.underline-bold {
  text-decoration: underline;
  font-weight: bold;
}

.circulo {
  width: 35px;
  height: 35px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #102949;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  padding: 6px;
}

.ant-table-row.table-cell-red {
  background-color: #FA8072 !important;
}

.ant-table-row.table-cell-green {
  background-color: #92FD70 !important;
 
}
.table-cell-green>.ant-table-cell-row-hover {
  background-color: #92FD70 !important;
}

.table-cell-red>.ant-table-cell-row-hover {
  background-color: #FA8072 !important;
}


.p {
  font-family: Arial;
  font: bold 11pt Arial;
}

.tabla-responsiva {
  max-width: 100%;
  margin-left: 20px;
  margin-right: 20px;
}

.tabla-responsiva table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.tabla-responsiva th,
.tabla-responsiva td {
  padding: 8px;
  text-align: left;
  vertical-align: middle;
  border: 1px solid #ddd;
}

@media (max-width: 767px) {

  .tabla-responsiva th,
  .tabla-responsiva td {
    padding: 5px;
  }
}